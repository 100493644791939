import sectionsFinder from "../../anaylsis/regex/finders/sectionsFinder";
import exhibitsFinder from "../../anaylsis/regex/finders/exhibitsFinder";
import schedulesFinder from "../../anaylsis/regex/finders/schedulesFinder";
import annexesFinder from "../../anaylsis/regex/finders/annexesFinder";
import termsFinder from "../../anaylsis/regex/finders/termsFinder";
import articlesFinder from "../../anaylsis/regex/finders/articlesFinder";
import { getAllClausesAndAlts } from "../../../utilities/clauseHelpers";

const analyzeDocument = (html) => {
  const setChildrenOnClauses = (clauses, allAltsAndClauses) => {
    for (var i = 0; i < clauses.length; i++) {
      var children = [];

      var definition =
        clauses[i].definition && clauses[i].definition.length > 0
          ? clauses[i].definition
              .map((r) => r.text)
              .reduce((prev, next) => prev + next)
          : "" +
            (clauses[i].definedDefinition &&
            clauses[i].definedDefinition.length > 0
              ? clauses[i].definedDefinition
                  .map((r) => r.text)
                  .reduce((prev, next) => prev + next)
              : "");

      for (var j = 0; j < allAltsAndClauses.length; j++) {
        var regEx = new RegExp(
          allAltsAndClauses[j].alternativeTerm
            .replaceAll("(", "\\(")
            .replaceAll(")", "\\)")
            .replaceAll(".", "\\."),
          "ig"
        );

        if (regEx.test(definition)) {
          children.push(allAltsAndClauses[j].word);
          definition = definition.replaceAll(regEx, allAltsAndClauses[j].id);
        }
      }

      clauses[i].children = children;
    }
  };

  var div = document.createElement("div");
  div.innerHTML = html.replace(/(\r\n|\n|\r)/gm, " ").trim();

  const sections = sectionsFinder().getSections(div);
  const exhibits = exhibitsFinder().getExhibits(div);
  const schedules = schedulesFinder().getSchedules(div);
  const annexes = annexesFinder().getAnnexes(div);
  const articles = articlesFinder().getArticles(div);
  const terms = termsFinder().getTerms(div);


  const allAltsAndClauses = getAllClausesAndAlts(
    terms,
    sections,
    exhibits,
    schedules,
    annexes,
    articles
  );

  setChildrenOnClauses(terms, allAltsAndClauses);
  setChildrenOnClauses(sections, allAltsAndClauses);
  setChildrenOnClauses(exhibits, allAltsAndClauses);
  setChildrenOnClauses(schedules, allAltsAndClauses);
  setChildrenOnClauses(annexes, allAltsAndClauses);
  setChildrenOnClauses(articles, allAltsAndClauses);

  
  


  return {
    terms,
    sections,
    exhibits,
    schedules,
    annexes,
    articles,
    raw: html,
  };
};

export default analyzeDocument;
