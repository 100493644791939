import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  getSelectedText,
  removeTextAndAddNewContent,
  putRedlinesOffice,
  stripHtmlAndSpecialChars,
} from "../../actions/prompt";
import { toast } from "react-toastify";
import { ITerm } from "../../interfaces/ITermsInterface";
import { IMessage } from "../../../utilities/types";
import { getModelId, SELECTED_TEXT_CHARACTER_LIMIT } from "../../../utilities/constants";
import TermLink from "../../components/TermLink";
import JsxParser from "react-jsx-parser";
import TermModal from "../../../utilities/TermModal";
import { environment_conf } from "../../../utilities/environment";
import Redline from "../../components/WriterSearch/Redline";
import { getDocumentSegmentedData, getDocumentSpecificNamespace, getTermsWords, ingestDoc } from "../../../utilities/utils";
import { useUser } from "../../hooks/useUser";
import { useAnalysis } from "../../hooks/useAnalysis";
import { Button } from "../../components/ui/Button";
import { LinkButton } from "../../components/ui/LinkButton";
import { useChat } from "../../hooks/useChat";
import PromptInput from "../../components/PromptInput";
import InfoBox from "../../components/InfoBox/InfoBox";

const extractNewTerms = (text, currentTerms) => {
  const terms = new Set();
  
  const strippedText = text
    .replace(/<h[1-6]>.*?<\/h[1-6]>/gi, '')
    .replace(/<[^>]+>/g, '')
    .replaceAll('"', '"')
    .replaceAll('"', '"')
    .replaceAll(',', '')
    .trim();

  // Helper function to check if a term appears independently in the text
  const isIndependentTerm = (term) => {
    const escapedTerm = term.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const standAlonePattern = new RegExp(`\\b${escapedTerm}\\b`, 'g');
    const matches = [...strippedText.matchAll(standAlonePattern)];
    
    return matches.some(match => {
      const startPos = match.index;
      const endPos = startPos + term.length;
      
      return !currentTerms.some(currentTerm => {
        const termPattern = new RegExp(`\\b${currentTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`, 'g');
        const termMatches = [...strippedText.matchAll(termPattern)];
        
        return termMatches.some(termMatch => {
          const termStart = termMatch.index;
          const termEnd = termStart + currentTerm.length;
          return startPos >= termStart && endPos <= termEnd;
        });
      });
    });
  };

  const segments = strippedText.split(/[.:]|\n/);  // Split by periods, colons, and newlines
  
  segments.forEach(segment => {
    // Process the segment to find consecutive capitalized words
    const words = segment.trim().split(/\s+/);
    let potentialTerm = [];
    
    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();
      
      // Check if word starts with capital letter
      if (/^[A-Z]/.test(word)) {
        potentialTerm.push(word);
      } else if (potentialTerm.length > 0) {
        const term = potentialTerm.join(' ').trim();
        
        if (!ignoreTerm(term) && isIndependentTerm(term)) {
          console.log('Found potential term:', term); // Debug log
          terms.add(term);
        }
        potentialTerm = [];
      }
    }
    
    // Handle last term in segment
    if (potentialTerm.length > 0) {
      const term = potentialTerm.join(' ').trim();
      if (!ignoreTerm(term) && isIndependentTerm(term)) {
        console.log('Found potential term at end:', term); // Debug log
        terms.add(term);
      }
    }

    // Also look for quoted terms
    const quotedTerms = segment.match(/"([^"]+)"/g) || [];
    quotedTerms.forEach(quotedTerm => {
      const term = quotedTerm.replace(/^"|"$/g, '').trim();
      if (/^[A-Z]/.test(term) && !ignoreTerm(term) && isIndependentTerm(term)) {
        console.log('Found quoted term:', term); // Debug log
        terms.add(term);
      }
    });
  });

  const extractedTerms = Array.from(terms);
  console.log('Final extracted terms:', extractedTerms); // Debug log
  return extractedTerms;
};


// Helper function to ignore common words
function ignoreTerm(term) {
  const ignoreWords = [
    "and", "or", "include", "includes", "including","any","this",
    "without limitation", "will", "shall", "herein",
    "within", "in", "section", "hereof", "hereby", "hereto", "hereunder",
    "from", "from and including", "to", "until","if", "as",
    "through", "as is", "the", "The", "you", "we"
  ];

  if (ignoreWords.includes(term.toLowerCase())) return true;
  if (term.length < 2) return true;
  if (/\d/.test(term) && !term.includes(' ')) return true;
  
  return false;
}

// Helper function to create regex-enhanced items
const createRegexItems = (terms: ITerm[], sections: ITerm[]) => {
  return [...terms, ...sections]
    .sort((a, b) => b.word.length - a.word.length)
    .map(item => {
      
      const word = new RegExp(`\\b${item.word.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`, "i")
      const aliases = item?.aliases?.map(alias => 
          new RegExp(`\\b${alias.word.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`, "i")
        ) || []

      return {
        ...item,
        regex: [
          word,
          ...aliases
        ]
      }
    });
};



const findMatches = (part: string, items: Array<ITerm & { regex: RegExp[] }>, newTerms: string[]) => {
  const matchMap = new Map<number, {
    end: number;
    term: ITerm | null;
    text: string;
  }>();

  // Helper function to check for overlaps
  const hasOverlap = (start: number, end: number) => {
    let isOverlapping = false;
    matchMap.forEach((existing, existingStart) => {
      if ((start >= existingStart && start < existing.end) ||
          (end > existingStart && end <= existing.end) ||
          (start <= existingStart && end >= existing.end)) {
        isOverlapping = true;
      }
    });
    return isOverlapping;
  };

  // First find matches for existing terms - case insensitive
  items.forEach(({ regex, ...item }) => {
    regex.forEach(regexPattern => {
      const pattern = new RegExp(`\\b${regexPattern.source}\\b[:;,.]?`, 'gi');
      let match;
      
      while ((match = pattern.exec(part)) !== null) {
        const start = match.index;
        const cleanText = match[0].replace(/[:;,.]$/, '');
        const end = start + cleanText.length;
        
        // Only add if no overlap and not part of a longer existing match
        if (!hasOverlap(start, end)) {
          matchMap.set(start, {
            end,
            term: item,
            text: cleanText
          });
        }
      }
    });
  });

  // Then find matches for new terms - case sensitive
  newTerms.forEach(newTerm => {
    const escapedTerm = newTerm
      .replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
      .replace(/\s+/g, '\\s+');
      
    let pattern = new RegExp(`(?:")?\\b${escapedTerm}\\b(?:")?`, 'g');
    let match;
    
    while ((match = pattern.exec(part)) !== null) {
      const start = match.index;
      const end = start + match[0].length;
      
      if (!hasOverlap(start, end)) {
        matchMap.set(start, {
          end,
          term: null,
          text: match[0].replace(/^"|"$/g, '').trim()
        });
      }
    }
  });

  return Array.from(matchMap.entries())
    .sort(([posA], [posB]) => posB - posA);
};

const processTextPart = (
  part: string, 
  items: Array<ITerm & { regex: RegExp[] }>, 
  newTerms: string[],
  setCurrentTerm: (termId: string) => void
) => {
  if (/<[^>]+>|&[^;]+;/.test(part)) {
    return part;
  }

  // Pre-process the part to normalize quotes and whitespace
  const normalizedPart = part
    .replace(/[""]|"/g, '"')
    .replace(/\s+/g, ' ')
    .trim();

  const sortedMatches = findMatches(normalizedPart, items, newTerms);
  let processedPart = normalizedPart;

  // Process matches from end to start to avoid position shifting
  for (const [start, match] of sortedMatches) {
    const word = match.text
      .replace(/^"|"$/g, '')  // remove surrounding quotes
      .trim();

    const termLink = match.term === null
      ? `<TermLink termId={null}>${word}</TermLink>`
      : `<TermLink setCurrentTerm={setCurrentTerm} termId="${match.term.id}">${word}</TermLink>`;

    processedPart = 
      processedPart.slice(0, start) +
      termLink +
      processedPart.slice(match.end);
  }

  return processedPart;
};

export const hyperlinkTermsAndSections = (
  text: string,
  terms: ITerm[],
  newTerms: string[],
  sections: ITerm[],
  articles: ITerm[],
  setCurrentTerm: (termId: string) => void
) => {
  const regexItems = createRegexItems(terms, [...sections, ...articles]);

  const elements = text
    .split(/(<[^>]+>|&[^;]+;)/)
    .map(part => processTextPart(part, regexItems, newTerms, setCurrentTerm));

  return (
    <JsxParser
      bindings={{ setCurrentTerm }}
      jsx={elements.join("")}
      components={{ TermLink }}
    />
  );
};

type Compare = {
  suggestedText: string;
  originalText: string;
};

const Prompt = () => {

  //const [segmentDatWorker] = useWorker(getDocumentSegmentedData);


  const { analysis } = useAnalysis();
  const { user, masterUserId } = useUser();
  const { messages, addMessage, clearChat, setChat, removeLastMessage } = useChat();

  const { aiApiKey, aiModel, aiProvider } = user;

  const [loading, setLoading] = useState(false);
  const [insertingDocument, setInsertingDocument] = useState(false);

  const [currentTerm, setCurrentTerm] = useState<string>();
  const [compare, setCompare] = useState<Compare>(null);

  const [newTerms, setNewTerms] = useState<string[]>([]);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const [forAccept, setForAccept] = useState(null);


  /*useEffect(() => {

    async function cb(event: Office.DocumentSelectionChangedEventArgs) {  
    //  const data = await segmentDatWorker();
      console.log('documentSegments: ', event);
    }

    listenToDocumentChanges(cb);

    return () => {
      removeDocumentChangeListener(cb);
    };
  }, []);*/

  const terms = analysis.clauses.filter((r) => r.clauseType === "TERM");
  const sections = analysis.clauses.filter((r) => r.clauseType === "SECTION");
  const articles = analysis.clauses.filter((r) => r.clauseType === "ARTICLE");

  const promptTextarea = useRef<HTMLTextAreaElement>(null);

  const openAiPrompt = async (prompt:string) => {
    setLoading(true);
    setCompare(null)
    setNewTerms([])

    const selectedText = await getSelectedText();

    var prompt = prompt;

    if (selectedText.length > SELECTED_TEXT_CHARACTER_LIMIT) {
      toast.error(
        "Please select less than 10000 characters at a time to allow us to best process your messages",
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      setLoading(false);
      return;
    }

    let question = ""

    const isEmpty = prompt.trim().length < 1


    if (isEmpty && selectedText.trim() != "") {
      // User has selected a text but haven't provided any input
      prompt = "What does the highlighted text mean?"
      question = `What does the highlighted text mean: #${selectedText}# `
    } else if (!isEmpty && selectedText.trim() != "") {
      // User has selected some text and is asking a question regarding that
      question = `${prompt} Please use the following information about the contract to aid your response: #${selectedText}#.`
    } else if (isEmpty && selectedText.trim() == "") {
      // User has not selected nor is asking any question
      toast.error("Please input some messages.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setLoading(false);
      return;
    } else {
      // User is asking a question
      question = prompt
    }

    addMessage({
      username: user.name === "" ? "You" : user.name,
      content: prompt,
      created: new Date().toISOString(),
      type: "user",
    });


    try {
      const data = await getDocumentSegmentedData()
      const namespace = await getDocumentSpecificNamespace(masterUserId, data)

      const allTermsTitle = await getTermsWords()

      const response = await axios.post<{
        output: string;
        consideratons: string[];
      }>(`${environment_conf.base_llm_domain}/query`, {
        question: question,
        apiKey: aiApiKey,
        modelName: aiModel,
        provider: aiProvider,
        messages: messages.map((m) => ({ type: m.type, content: m.content })),
        terms: allTermsTitle,
        namespace: namespace,
        modelId: `${getModelId(aiModel)}`,
        masterUserId: `${masterUserId}`,
      });
     /* const response = {
        data: {
          output: "<h3>New Name for Synthetic Lease Obligation</h3>\n<p>A suggested new name for Synthetic Lease Obligation is Synthetic Test Obligations. You can try also Synthetic Rent Obligations or \"Synthetic Rent2 Obligations\" </p>"
        }
      }*/

       /* const response = {
          data: {
            output: `<h3>Section 2.01 Committed Loans</h3>
<p>Subject to the terms and conditions set forth herein, each Lender almost agrees to make money (each such loan, a <strong>"Committed Loan"</strong>) denominated in Dollars or in one or more Alternative Currencies to the Borrower from time to time on any Business Day during the Availability Period, in an aggregate amount not to exceed at any time outstanding the amount of such Lender's Commitment; provided, that, after giving effect to any Committed Borrowing, (i) the Total Outstandings shall not exceed the Aggregate Commitments, and (ii) the aggregate Outstanding Amount of the Committed Loans of any Lender, plus such Lender's Applicable Percentage of the Outstanding Amount of all L/C Obligations, plus such Lender's Applicable Percentage of the Outstanding Amount of all Swing Line Loans shall not exceed such Lender's Commitment.</p>
<p>Within the limits of each Lender's Commitment, and subject to the other terms and conditions hereof, the Borrower may borrow under this Section 2.01, prepay under Section 2.05, and reborrow under this Section 2.01. Committed Loans may be Base Rate Committed Loans or Eurocurrency Rate Loans, as further provided herein. Skus aj tento To-To Je New Commitment Obligations, alebo tento "New Term Uvodozvky"</p>`
          }
        }*/

        /*  /*const response = {
            data: {
              output: `<h3>Section 2.01 Committed Loans</h3>
<p>Subject to the terms and conditions set forth herein, each "Lender" severally new WORD to make loans (each such loan, a "Committed Loan") denominated in Dollars or in one or more "Alternative Currencies" to the "Borrower" from time to time on any "Business Day" during the "Availability Period," in an aggregate amount not to exceed at any time outstanding the amount of such Lender's "Commitment"; provided, that, after giving effect to any "Committed Borrowing," (i) the "Total Outstandings" shall not exceed the "Aggregate Commitments," and (ii) the aggregate Outstanding Amount of the Committed Loans of any Lender, plus such Lender's "Applicable Percentage" of the Outstanding Amount of all "L/C Obligations," plus such Lender's Applicable Percentage of the Outstanding Amount of all "Swing Line Loans" shall not exceed such Lender's Commitment. Within the limits of each Lender's Commitment, and subject to the other terms and conditions hereof, the Borrower may borrow under this Section 2.01, prepay under Section 2.05, and reborrow under this Section 2.01. Committed Loans may be "Base Rate Committed Loans" or "Eurocurrency Rate Loans," as further provided herein.</p>`
            }
          }*/

           /* const response = {
              data: {
                output: `<h3>Key Terms and Conditions in Section 2.01</h3>

<ul>
  <li><strong>Loan Agreement:</strong> Each Lender agrees to make loans, referred to as "Committed Loans," to the Borrower.</li>
  <li><strong>Currency:</strong> Loans can be denominated in Dollars or in one or more Alternative Currencies.</li>
  <li><strong>Availability Period:</strong> Loans can be made from time to time on any Business Day during the Availability Period.</li>
  <li><strong>Borrowing Limitations:</strong>
    <ul>
      <li>The aggregate amount of loans outstanding at any time cannot exceed the Lender's Commitment.</li>
      <li>Total Outstandings must not exceed the Aggregate Commitments.</li>
       <li>Availability Cariod: can use alse by as "Borrowing Flexibility Test"</li>
      <li>The sum of the Outstanding Amount of Committed Loans, the Lender's Applicable Percentage of L/C Obligations, and Swing Line Loans must not exceed the Lender's Commitment.</li>
    </ul>
  </li>
  <li><strong>Borrowing Flexibility:</strong> Within the limits of each Lender's Commitment, the Borrower may borrow, prepay, and reborrow.</li>
  <li><strong>Types of Loans:</strong> Committed Loans may be Base Rate Committed Loans or Eurocurrency Rate Loans.</li>
</ul>`
              }
            }*/

             /* const response = {
                data: {
                  output: `"<h3>Suggested Clause for Lost Certificates</h3>\n\n<p>In accordance with Section 3.3 regarding Lost Certificates, here is a suggested clause:</p>\n\n<h4>Lost, Stolen, or Destroyed Certificates</h4>\n\n<p>If any Certificate is reported as lost, stolen, or destroyed, the holder must provide an affidavit in a form and substance reasonably acceptable to the Parent, affirming the loss, theft, or destruction of the Certificate. Upon receipt of such affidavit, the Paying Agent will issue the Merger Consideration to the holder, as entitled under Article III, in exchange for the lost, stolen, or destroyed Certificate.</p>\n\n<p>This clause ensures that the process for dealing with lost, stolen, or destroyed Certificates is clear and provides a mechanism for the holder to receive their entitled Merger Consideration.</p>"`
                }
              }*/

               /*/ const response = {
                  data: {
                    output: ` "<h3>ARTICLE III - EFFECT OF THE MERGER ON CAPITAL STOCK</h3>\n<ul>\n  <li><strong>Section 3.1:</strong> Details the effect on securities, including the conversion of Acquisition Sub capital stock, cancellation of certain Company securities, and conversion of Company securities into the right to receive the Merger Consideration.</li>\n  <li><strong>Section 3.2:</strong> Covers the payment for securities and exchange of certificates, including the designation of a Paying Agent and procedures for exchanging Company Common Stock for the Merger Consideration.</li>\n  <li><strong>Section 3.3:</strong> Addresses the treatment of Company stock options and other equity-based awards.</li>\n  <li><strong>Section 3.4:</strong> Discusses the treatment of Company warrants.</li>\n  <li><strong>Section 3.5:</strong> Provides for the treatment of Company convertible notes.</li>\n  <li><strong>Section 3.6:</strong> Covers the treatment of restricted stock units (RSUs) and other equity-based awards.</li>\n  <li><strong>Section 3.7:</strong> Addresses the treatment of Company restricted stock awards.</li>\n</ul>"
`
                  }
                }*/

     const langchainOutput = response.data.output.replace(/^<h[1-6]>.*?<\/h[1-6]>/m, '')
  
     const revisedContent = langchainOutput
  
      // Check if prompt suggests generating new content
      const guardrialIndicators = [
        'suggest',
        'create new',
        'give me',
        'recommend',
        'provide',
        'generate',
        'make',
        'define new',
        'write',
        'draft',
        'propose'
      ];

      // Convert prompt to lowercase for case-insensitive matching
      const promptLower = prompt.toLowerCase();
      
      // Check if prompt contains any suggestive words
      const isSuggestive = guardrialIndicators.some(word => 
        promptLower.includes(word.toLowerCase())
      );

      let nt = []

      if (isSuggestive) {
        nt = extractNewTerms(langchainOutput,allTermsTitle)
        setNewTerms(nt ?? [])
      }


      const renderContentSections = hyperlinkTermsAndSections(
        revisedContent,
        terms,
        nt ?? [],
        sections,
        articles,
        setCurrentTerm
      );

      addMessage({
        username: "Spectacles",
        content: revisedContent,
        renderContent: renderContentSections,
        created: new Date().toISOString(),
        type: "assistant",
      });

      promptTextarea.current.scrollTo(0, promptTextarea.current.scrollHeight);


    } catch (error) {
      toast.error(`Error caught in the main try bloc: ${error}`)
      
      removeLastMessage()

      if (axios.isAxiosError(error)) {
        const { status, data } = error.response;
        console.log(status, data);
        if (status !== 500) {
          const message = data.message;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          const message = data.message;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } else {
        console.error(error);
        toast.error(error, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } finally {
      setLoading(false);
    }
  };



  const onInsert = async (content: string) => {
    // await applyStrikethrough();
    // await insertContentNextSelected(content);
    const strippedContent = stripHtmlAndSpecialChars(content);
    await removeTextAndAddNewContent(strippedContent);
  };

  const onInsertCompare = async (content: string) => {
    //await putRedlines(content);
    const a  =  await putRedlinesOffice(content);
  };

  const onCompare = async (aiOutput: string) => {
    const selectedText = await getSelectedText();

    if (!selectedText) {
      toast.error("Please select some text to compare against");
      return;
    }

    const strippedContent = stripHtmlAndSpecialChars(aiOutput);

    setCompare({
      originalText: selectedText,
      suggestedText: strippedContent,
    });
  };

  const onSubmit = async () => {
    setInsertingDocument(true);

    clearChat()
    setCompare(null)

    try {
      await ingestDoc(masterUserId, aiApiKey);
      toast.info("Data Ingested, start chatting away!");
    } catch (error) {
      toast.error(error)
      console.log(error);
    } finally {
      setInsertingDocument(false);
    }
  };

  return (
    <div className="Ai-Vision">
 
       <Button onClick={onSubmit}>
        {insertingDocument ? `Ingesting...` :`Ingest Doc`}
      </Button>

      {messages.length < 1 ? <InfoBox /> : null}
  
       <div className="messages">
          {messages.map((message, index) => {
            return (
              <div
                className="message"
                key={index}
                ref={index === messages.length - 1 ? messagesEndRef : null}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: message.type === "user" ? "right" : "left",
                    marginBottom: "8px",
                  }}
                >
                  {message.type === "assistant" ? (
                    <span style={{ marginRight: "10px" }}>
                      <img
                        width="32"
                        height="32"
                        src="/assets/logo-filled.png"
                        alt="Spectacles Logo"
                      />
                    </span>
                  ) : (
                    <strong className="username">{message.username}</strong>
                  )}
                  <p style={{ font: "bold" }}>
                    {new Intl.DateTimeFormat("default", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(message.created))}
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor:
                      message.type === "user"
                        ? "rgb(0, 120, 212)"
                        : "whitesmoke",
                    color: message.type === "user" && "white",
                  }}
                  className="message-content"
                >
                  {message.renderContent ?? <p>{message.content}</p>}
                </div>
                {message.username === "Spectacles" && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "8px" }} className="insertBtn">
                      <button onClick={() => onInsert(message.content)}>
                        Insert
                      </button>
                    </div>
                    <div className="insertBtn">
                      <button onClick={() => onCompare(message.content)}>
                        Compare
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {newTerms.length ? (
            <div className="message-considerations">
              <p style={{ marginBottom: '6px' }}>
                It seems as though the following new terms have been added in
                the AIs response:
              </p>
              {newTerms.map((c) => (
                <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div style={{fontSize: '13px', textDecoration: 'underline', marginBottom: '1px'}}>{c}</div> 
                  <button style={{color: 'green', textDecoration: 'underline', fontSize: '11px'}} onClick={() => {
                      openAiPrompt(`Create definition for new term you just added to the recent output "${c}"`)
                  }}>Generate</button>
                  <button style={{color: 'red', textDecoration: 'underline', fontSize: '11px'}} onClick={() => setNewTerms(newTerms.filter((t) => t !== c))}>Remove</button>
                </p>
              ))}
              <LinkButton
              style={{ marginTop: '6px', fontSize: '14px', fontWeight: 'bold' }}
                onClick={() => {
                  openAiPrompt(`Create definitions for each of the new terms you just added to the recent output, "${newTerms.join(", ")}"`)
                }}
              >
                Generate defintions for these new terms
              </LinkButton>
            </div>
          ) : null}
          {compare && (
            <div className="message-redline">
             <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
             <button onClick={() => setCompare(null)}>Close</button>
             <button onClick={() => onInsertCompare(compare.suggestedText)}>Insert</button>
             </div>
              <Redline
                originalText={compare.originalText}
                suggestedText={compare.suggestedText}
              />
            </div>
          )}
        </div>
    


        <PromptInput textareaRef={promptTextarea} isLoading={loading} clearChat={clearChat} aiPrompt={openAiPrompt} />
      

  
      {currentTerm && (
        <TermModal id={currentTerm} setCurrentTerm={setCurrentTerm} />
      )}
    </div>
  );

};

export default Prompt