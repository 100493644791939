import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Plus, Minus } from "react-feather";

import { Section, Container } from "../../components/ui/Grid";
import {
  IUser,
  LICENSE_TYPES,
  ANALYSIS_ENGINE,
} from "../../interfaces/IUserInterface";
import { IRegexExpressionSettings } from "../../interfaces/IRegexExpressionSettings";
import {
  increaseFontSize,
  increaseFontSizeType,
  decreaseFontSize,
  decreaseFontSizeType,
  changeFontFamily,
  changeFontFamilyType,
  resetToDefault,
  resetToDefaultType,
} from "../../actions/font";

import { setLicenseType, setLicenseTypeType, setAnalysisEngine, setAnalysisEngineType } from "../../actions/user";
import {
  enableRegexExpression,
  disableRegexExpression,
} from "../../actions/regex";

import { SettingsList, ResetToDefaults } from "./styled";

export interface ISettingsScreenProps {
  user: IUser;
  regexList: IRegexExpressionSettings[];
  increaseFontSize: increaseFontSizeType;
  decreaseFontSize: decreaseFontSizeType;
  changeFontFamily: changeFontFamilyType;
  resetToDefault: resetToDefaultType;
  setLicenseType: setLicenseTypeType;
  disableRegexExpression: disableRegexExpressionType;
  enableRegexExpression: enableRegexExpressionType;
}

export interface ISettingsScreenState {
  selectedFont: React.ReactText;
}

class SettingsScreen extends React.Component<
  ISettingsScreenProps,
  ISettingsScreenState
> {
  constructor(props: ISettingsScreenProps) {
    super(props);

    this.state = {
      selectedFont: undefined,
    };

    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleAnalysisEngineChange =
      this.handleAnalysisEngineChange.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
  }

  handleFontChange(event) {
    this.setState({ selectedFont: event.target.value });
    this.props.changeFontFamily(event.target.value);
  }

  handleLicenseTypeChange(type: string) {
    if (type === LICENSE_TYPES.PREMIUM) {
      this.props.setLicenseType(LICENSE_TYPES.PREMIUM);
    } else {
      this.props.setLicenseType(LICENSE_TYPES.BASIC);
    }
  }

  handleAnalysisEngineChange(event) {
    const engine = event.target.value;
    if (engine.toUpperCase() === ANALYSIS_ENGINE.REGEX) {
      this.props.setAnalysisEngine(ANALYSIS_ENGINE.REGEX);
    } else {
      this.props.setAnalysisEngine(ANALYSIS_ENGINE.AI);
    }
  }

  resetToDefault() {
    this.props.resetToDefault();
  }

  toggleRegex(regex) {
    if (regex.enabled) {
      this.props.disableRegexExpression(regex.id);
    } else {
      this.props.enableRegexExpression(regex.id);
    }
  }

  render() {
    const { increaseFontSize, decreaseFontSize, resetToDefault } = this.props;

    var versionControl;
    if (this.props.user.admin) {
      versionControl = (
        <li>
          Toggle User Version:
          <select
            value={this.props.user.licenseType}
            onChange={(e: React.SyntheticEvent<HTMLSelectElement>) =>
              this.handleLicenseTypeChange(e.currentTarget.value)
            }
          >
            <option value={LICENSE_TYPES.BASIC}>Basic</option>
            <option value={LICENSE_TYPES.PREMIUM}>Premium</option>
          </select>
        </li>
      );
    } else {
      versionControl = (
        <li>
          User Version:
          <span>{this.props.user.licenseType}</span>
        </li>
      );
    }

    return (
      <Section>
        <h2 className="page-title">Settings</h2>
        <Container>
          <SettingsList>
            <li>
              Font Size:
              <span>
                <button onClick={() => this.props.increaseFontSize(0.1)}>
                  <Plus size={18} />
                </button>

                <button onClick={() => this.props.decreaseFontSize(0.1)}>
                  <Minus size={18} />
                </button>
              </span>
            </li>
            {this.props.user.admin && (
              <li>
                Font Family:
                <select
                  value={this.state.selectedFont}
                  onChange={this.handleFontChange}
                >
                  <option value="calibri">calibri</option>
                  <option value="roboto">roboto</option>
                  <option value="helvetica">helvetica</option>
                  <option value="Times New Roman">Times New Roman</option>
                </select>
              </li>
            )}

            {versionControl}

  

            {this.props.user.admin && (
              <li>
                <table>
                  <thead>
                    <tr>
                      <th>Enabled</th>
                      <th>Regex</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.regexList.map((regex, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              checked={regex.enabled}
                              onChange={this.toggleRegex.bind(this, regex)}
                            />
                          </td>
                          <td> {regex.text}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </li>
            )}
          </SettingsList>
          <ResetToDefaults onClick={resetToDefault}>
            Reset to Defaults
          </ResetToDefaults>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  regexList: state.regex,
});

const mapDispatchToProps = (dispatch) => ({
  increaseFontSize: (amount) => dispatch(increaseFontSize(amount)),
  decreaseFontSize: (amount) => dispatch(decreaseFontSize(amount)),
  changeFontFamily: (fontFamily) => dispatch(changeFontFamily(fontFamily)),
  resetToDefault: () => dispatch(resetToDefault()),
  setLicenseType: (licenseType) => dispatch(setLicenseType(licenseType)),
  disableRegexExpression: (regexId) =>
    dispatch(disableRegexExpression(regexId)),
  enableRegexExpression: (regexId) => dispatch(enableRegexExpression(regexId)),
  setAnalysisEngine: (analysisEngine) =>
    dispatch(setAnalysisEngine(analysisEngine)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
