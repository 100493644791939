import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "./style.css";
import { updateUserAiSetting } from "../../../utilities/server";
import { User } from "../../interfaces/IUserInterface";
import { toast } from "react-toastify";
import {
  getProviderByName,
  getProviderByNameValue,
  providers,
} from "../../../utilities/constants";
import { getApiKeys } from "../../../utilities/server";
import { useUser } from "../../hooks/useUser";

export const AiSettings = () => {
  const { masterUserId, user, setUserProperty, refreshUser } = useUser();
  const [modelWithApiKey, setModelWithApiKey] = useState([]);

  const { aiProvider, aiModel, aiApiKey } = user;

  useEffect(() => {
    getApiKeys(masterUserId, user.licenseType).then((data) => { 
      const selectedApiKey = data.find((key) => key.providerId === getProviderByName(aiProvider)?.providerId);
     setUserProperty('aiApiKey', selectedApiKey?.apiKey);
     setModelWithApiKey(data.filter((model) => model.apiKey));
    });
  }, [aiProvider]);




  const providerModels = getProviderByNameValue(aiProvider)?.models ?? [];

  const updateChanges = async () => {
    const res = await updateUserAiSetting(
      user.accessToken,
      aiProvider,
      aiModel,
      aiApiKey
    );
    if (res) {
      toast.success("Keys Updated!", {
        toastId: "keys-update-success",
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error("Error occured", {
        toastId: "keys-update-error",
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("error");
    }
  };

  return (
    <div className="Ai-Settings">
      <div>
        <select
          name="provider"
          id="provider"
          onChange={(e) => setUserProperty("aiProvider", e.target.value)}
          value={aiProvider}
        >
          <option value={null}>Please Select AI Provider</option>
          {providers.filter((provider) => modelWithApiKey.some((model) => model.providerId === provider.providerId)).map((provider) => (
            <option key={provider.value} value={provider.value}>
              {provider.name}
            </option>
          ))}
        </select>
        {!aiProvider && (
          <span className="error">{"You have to select AI provider."}</span>
        )}
      </div>
      <div>
        <select
          name="model"
          id="model"
          onChange={(e) => setUserProperty('aiModel', e.target.value)}
          value={aiModel}
        >
          <option value={null}>Please Select AI Model</option>
          {providerModels.map((model) => (
            <option key={model.value} value={model.value}>
              {model.name}
            </option>
          ))}
        </select>
        {!aiModel && (
          <span className="error">{"You have to select AI model."}</span>
        )}
      </div>
  
      <div>
        <button type="button" className="updateBtn" onClick={updateChanges}>
          Update Changes
        </button>
      </div>
    </div>
  );
};

export default AiSettings;