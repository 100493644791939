import * as React from 'react';
import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import { LinkButton } from './ui/LinkButton';
const prompts = [
  "What does Section [Section 3.5(a)] mean?",
  "What is the exact text of Section [Section 7]?",
  "What does the [Availability Period] mean?", 
  "What is the exact definition of [Availability Period]?",
  "Does Section [Section 3.5(a)] have any references to other sections? What are they?",
  "Does the definition of [Company Records] have any references to other Sections? What are they?",
  "Is there an obligation of [Borrower/Lender] to do [insert task]? In what Section? Or 'Where'?",
  "Can you summarize [Indemnification Obligations]?",
  "Can you summarize the highlighted text?",
  "What does the highlighted text mean?",
  "Can you make [insert request] change to the highlighted text?",
  "Can you suggest a clause with [insert specific request] features?",
  "Can you add [more specific feature] to the clause suggested?",
  "Can you conform this clause [feed it a clause] to fit this document?",
  "Can you conform this clause [feed it a clause] to have [insert specific request]?",
  "Can you tell me if there is a [insert] in this document?",
  "Can you give me the [purchase price/loan amount/price per share?",
  "What are the requirements of [Covenants]?"
]


export type InputPrompt = {
  prompt: string;
  guardrial: boolean;
  highlighted: boolean;
}
  
interface Props {
    isLoading: boolean;
    aiPrompt: (value: string) => void;
    clearChat: () => void;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const StyledTextarea = styled.textarea`
    width: 100%;
    resize: none;
    border: 1px solid grey;
    outline: none;
    overflow: auto;
    border-radius: 50px;
    padding: 1rem 2rem; 
`

const StyledButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  right: 1rem;
  top: 56%;
  aspect-ratio: 1;
  cursor: pointer;
  transform: translateY(-50%);
`

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
`

const PromptList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  position: absolute;
  bottom: 65px;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 100000;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  & > div {
    cursor: pointer;
    font-size: 13px;
    padding: 4px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`   


const PromptInput:React.FC<Props>  = ({isLoading, aiPrompt, clearChat, textareaRef}: Props) => {

  const [isPromptListOpen, setIsPromptListOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const promptRef = React.useRef<HTMLTextAreaElement>(null);

    function onEnter(event) {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents the default action of inserting a newline
            aiPrompt(prompt);
            setPrompt("");
          }
    }

    const autosizeTextarea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      };

      const handleClick = () => {
        if (promptRef.current) {
          aiPrompt(prompt);
          setPrompt("");
        }
      };
    
     const recommendedPrompts = prompts.filter((p) => p.toLowerCase().includes(prompt.trim().toLowerCase()));

    const handlePromptSelect = (selectedPrompt: string) => {
      setPrompt(selectedPrompt);
      setIsPromptListOpen(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
       <StyledWrapper ref={textareaRef}>
       <StyledTextarea
            ref={promptRef}
            name="text"
            id="text"
            autoComplete="off"
            onInput={autosizeTextarea}
            onChange={(e) => {
              setPrompt(e.target.value);
              setIsPromptListOpen(true);
            }}
            value={prompt}
            onKeyDown={onEnter}
            rows={1}
            placeholder={isLoading ? "Thinking..." : "Ask Spectacles"}
        />
        <StyledButton
      id="revise"
      type="button"
      onClick={handleClick}>
              <img
          width="24"
          height="24"
          src="/assets/spectacles-monocles.png"
          alt="Spectacles Monocles Logo"
        />
        </StyledButton>
          

     { (recommendedPrompts.length > 0 && prompt.length > 0 && isPromptListOpen) && (
          <PromptList>
          {recommendedPrompts.map((prompt, index) => (
            <div key={index} onClick={() => handlePromptSelect(prompt)}>
              {prompt}
            </div>
          ))}
         </PromptList>
     )}
       </StyledWrapper>
       <LinkButton onClick={() => clearChat()} style={{ textAlign: 'center' }}>
                 Clear Chat
               </LinkButton>
      </div>
    );
};

export default PromptInput;