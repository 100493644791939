import React from 'react';
import styled from 'styled-components';
const content = [
    {
        title: 'Ask',
        content: 'What does [Section 3.5(a)] mean?'
    },{
        title: 'Suggest',
        content: 'Can you suggest a [Buyer friendly] clause?'
    },
    ,{
        title: 'Compare & Insert',
        content: 'Compare suggested and insert into the document'
    }
]

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 16px;
`

const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;

    .title {
        font-weight: 600;
        font-size: 16px;
    }

    .content {
        font-size: 13px;
    }
`

const InfoBox = () => {
    return (
        <StyledWrapper>
            {content.map((item, index) => (
                <StyledItem key={index}>
                    <div className="title">{item.title}</div>
                    <div className="content">{item.content}</div>
                </StyledItem>
            ))}
        </StyledWrapper>
    );
};

export default InfoBox;